@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer base {

  body {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    @apply text-default bg-tertiary;
  }

  h1 {
    @apply !text-3xl mb-8 ;
  }

  h2 {
    @apply !text-2xl mb-4;
  }

  h1,
  h2 {
    @apply font-bold  !text-default;
  }

  label {
    @apply font-medium;
  }

  button {
    @apply text-sm;
  }
}

.o-table, table {
  @apply w-full;
}

td {
  @apply p-4 border-b-2 border-tertiary;
}

.o-table-row {
  @apply bg-white;
}

h2 {
  @apply text-lg;
}

.todo {
  background: #CCCCCC;
  font-size: 75%;
  padding: 4px;
}

.anticon {
  vertical-align: initial;
}

td.ant-table-column-sort {
  background-color: #ffffff;
}

button.ant-btn-primary {
  background: #9b0020;
}

/**
.todo {
  visibility: hidden;
  display: none;
}
*/

#root {
  @apply h-full;
}

i {
  background-color: #aaaaaa;
}
